import { template as template_7a595bc33ddb4865b04e6e7dbf152413 } from "@ember/template-compiler";
const FKLabel = template_7a595bc33ddb4865b04e6e7dbf152413(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
