import { template as template_06be3abb1f4f49918d7da7d6ea6fe006 } from "@ember/template-compiler";
const SidebarSectionMessage = template_06be3abb1f4f49918d7da7d6ea6fe006(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
