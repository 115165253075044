import { template as template_834138c64103425ebf25ab5667d9e609 } from "@ember/template-compiler";
const FKText = template_834138c64103425ebf25ab5667d9e609(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
